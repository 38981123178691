<template>
  <div class="common-layout">
    <router-view/>
  </div>
</template>

<style lang="scss">
#app {
  display: inherit;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  flex: 1;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

body{
  display: flex;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
}
.common-layout{
  flex: 1;
  background: url("@/assets/bg.svg") no-repeat 100% 100%;
}
</style>
