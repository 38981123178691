// jsonToUrlEncoded.ts
const jsonToUrlEncoded = (jsonObj: Record<string, any>): string => {
    const urlParams = new URLSearchParams();
    for (const key in jsonObj) {
      urlParams.append(key, jsonObj[key]);
    }
    return urlParams.toString();
  };

  export  const util = {
    jsonToUrlEncoded
  };

/**
 * 枚举- Text Avatars 左侧列表的类型
 * @param string Text text
 * @param string Avatars avatars
*/
export enum ListType {
  Text = 'text',
  Avatars = 'avatars'
}

/**
 * 聊天信息
 * @param string sender 辨别身份
 * @param string message 消息
 * @param Date timestamp 时间戳
 */
export interface ItemChatLog {
  sender: string; // 辨别身份
  message: string; // 消息
  timestamp: Date; // 时间戳
}
/**
 * 构建对话存储
 * @param Number id 对应数字脑的id
 * @param ItemChatLog chatlog 存储聊天记录
 */
export type CHAT_LIST = { id: number; chatLog: ItemChatLog[]; }[]