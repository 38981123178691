import fetchApi from "..";
import { util } from "@/util";
import { RelatedTexts, EmbeddingTexts } from "@/util/apiType"

/**
 * 根据文本获取向量内容
 * @param string input    输入内容
 * @param string brainId  数字脑id
 * @returns RelatedTexts
 */
const relatedTexts = async ({
    input,
    brainId
}:{
    input: string,
    brainId: string
}) => {
    const response = await fetchApi<RelatedTexts>('/knowledge/get_related_texts', {
        method: 'POST',
        body: util.jsonToUrlEncoded({input, brainId})
    })
    return response
}

/**
 * 新增加向量知识点
 * @param string input    输入内容
 * @param string knowledgebase_id  知识库id
 * @returns EmbeddingTexts
 */
const embeddingTexts = async ({
    input,
    knowledgebase_id
}:{
    input: string,
    knowledgebase_id: string
}) => {
    const response = await fetchApi<EmbeddingTexts>('/knowledge/store_embedding', {
        method: 'POST',
        body: util.jsonToUrlEncoded({input, knowledgebase_id})
    })
    return response
}

/**
 * 删除知识点
 * @param string knowledge_id  知识库id
 * @returns EmbeddingTexts
 */
const deleteEmbeddingById =  async ({
    knowledge_id
}:{
    knowledge_id: string
}) => {
    const response = await fetchApi<EmbeddingTexts>('/knowledge/deleteKnowledge', {
        method: 'POST',
        body: util.jsonToUrlEncoded({knowledge_id})
    })
    return response
}

/**
 * 更新知识点
 * @param string knowledge_id id
 * @param string content 内容 （可选）
 * @param string note 备注 (可选)
 */
const updateEmbeddingById = async ({
    knowledge_id,
    content,
    note
}:{
    knowledge_id: string,
    content?: string,
    note?: string
}) => {
    const payload: Record<string, string> = { knowledge_id };
    if (content) payload.content = content;
    if (note) payload.note = note;

    const response = await fetchApi<EmbeddingTexts>('/knowledge/updateKnowledge', {
        method: 'POST',
        body: util.jsonToUrlEncoded(payload)
    })
    return response
}


/**
 * 依据知识库id查询向量知识点
 * @param string knowledgebase_id  知识库id
 * @returns EmbeddingTexts
 */
const embeddingByKnId = async ({
    knowledgebase_id
}:{
    knowledgebase_id: string
}) => {
    const response = await fetchApi<RelatedTexts>(`/knowledge/getKnowledgesByKnowledgebaseId?knowledgebase_id=${knowledgebase_id}`, {
        method: 'GET'
    })
    return response
}


/**
 * 暴露方法出去
 */
export const embeddingFun = {
    relatedTexts,
    embeddingTexts,
    embeddingByKnId,
    updateEmbeddingById,
    deleteEmbeddingById
}