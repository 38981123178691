import fetchApi from "..";
import { KnowledgeBaseResponses, CreateKnowledgeBase, UpdateKnowledgeBaseById } from "@/util/apiType";
import { util } from "@/util";

/**
 * 创建
 */
const create = async ({
    body
}:{
    body:CreateKnowledgeBase
}) => {
    const response = await fetchApi<KnowledgeBaseResponses>('/knowledgebase/createKnowledgeBase', {
        method: 'POST',
        body: util.jsonToUrlEncoded(body)
    })
    return response
}
/**
 * 删除
 */
const deleteById = async ({
    id
}:{
    id: string
}) => {
    const response = await fetchApi<KnowledgeBaseResponses>('/knowledgebase/softDeleteKnowledgeBaseById', {
        method: 'POST',
        body: util.jsonToUrlEncoded({id})
    })
    return response
}
/**
 * 改
 */
const update = async ({
    body
}:{
    body: UpdateKnowledgeBaseById
}) => {
    const response = await fetchApi<KnowledgeBaseResponses>('/knowledgebase/updateKnowledgeBase', {
        method: 'POST',
        body: util.jsonToUrlEncoded(body)
    })
    return response
}
/**
 * 查
 */
/**
 * 依据用户id查知识库
 */
const queryByUserId = async ({
    userId
}: {
    userId: string
}) => {
    const response = await fetchApi<KnowledgeBaseResponses>(`/knowledgebase/getKnowledgeBaseByUserId?userId=${userId}`, {
        method: 'GET'
    })
    return response
}
/**
 * 根据知识库名称模糊查询
 */
const queryByName = async ({
    userId,
    name
}: {
    userId: string,
    name: string
}) => {
    const response = await fetchApi<KnowledgeBaseResponses>(`/knowledgebase/getKnowledgeBaseByName?name=${name}&userId=${userId}`, {
        method: 'GET'
    })
    return response
}
/**
 * 根据数字脑id获取知识库信息
 */
const queryByBrainId = async ({
    brainId
}: {
    brainId: string
}) => {
    const response = await fetchApi<KnowledgeBaseResponses>(`/knowledgebase/getKnowledgeBasesByBrainId?brainId=${brainId}`, {
        method: 'GET'
    })
    return response
}

/**
 * 关联数字脑
 */
const addCorrelation = async ({
    brainId,
    knowledgeBaseId
}:{
    brainId: string,
    knowledgeBaseId: string
}) => {
    const response = await fetchApi<KnowledgeBaseResponses>('/knowledgebase/addKnowledgeBaseToBrain', {
        method: 'POST',
        body: util.jsonToUrlEncoded({brainId, knowledgeBaseId})
    })
    return response
}

/**
 * 取消关联
 */
const delCorrelation = async ({
    brainId,
    knowledgeBaseId
}:{
    brainId: string,
    knowledgeBaseId: string
}) => {
    const response = await fetchApi<KnowledgeBaseResponses>('/knowledgebase/removeKnowledgeBaseFromBrain', {
        method: 'POST',
        body: util.jsonToUrlEncoded({brainId, knowledgeBaseId})
    })
    return response
}

/**
 * 导出知识库相关数据
 */
export const kbFun = {
    create,
    deleteById,
    update,
    queryByUserId,
    queryByName,
    queryByBrainId,
    addCorrelation,
    delCorrelation
}