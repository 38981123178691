
import { Options, Vue } from 'vue-class-component';
import FristSetLogo from '@/components/frist/SetLogo.vue'; // @ is an alias to /src

@Options({
  components: {
    FristSetLogo,
  },
})
export default class SetLogoView extends Vue {}
