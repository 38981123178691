// src/api/index.ts
import router from '@/router';
const BASE_URL:string = process.env.VUE_APP_BASE_URL
type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

// type CODE = 200 | 400 | 401 | 403 | 404 | 500

interface FetchOptions {
  method?: Method;
  headers?: HeadersInit;
  body?: BodyInit;
  mode?: RequestMode;
  cache?: RequestCache;
  credentials?: RequestCredentials;
  redirect?: RequestRedirect;
  referrer?: string;
  referrerPolicy?: ReferrerPolicy;
  integrity?: string;
}

interface FetchResponse<T> {
  data: T;
  code: number;
  message: string;
}

function fetchApi<T>(url: string, options?: FetchOptions): Promise<FetchResponse<T>> {
  const requestUrl = `${BASE_URL}${url}`;
  const requestOptions: RequestInit = {
    ...options,
    headers: {
      ...options?.headers,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    credentials: 'include',
  };
  return fetch(requestUrl, requestOptions)
    .then(async (response) => {
      const responseData = await response.json();
      if(responseData?.code === 401){
        router.push('/')
      }
      return {
        data: responseData,
        code: response.status,
        message: response.statusText,
      };
    })
    .catch((error) => {
      console.error(`Fetch failed: ${error}`);
      throw error;
    });
}

export default fetchApi;
