
interface Brain {
    id: number,
    name: string,
    info: string,
    picurl: string
}

interface BrainState {
    brain: Brain[],
    isLoading: boolean 
}

export default{
    namespaced: true,
    state: {
        brain: [],
        isLoading: false
    } as BrainState,
    getters: {
        brain: (state: BrainState) => state.brain,
        isLoading: (state: BrainState) => state.isLoading
    },
    mutations: {
        setBrain: (state: BrainState, brain: Brain) => {
            state.brain = [{...brain}] 
        },
        setIsLoading:(state: BrainState, isLoading: boolean) => {
            state.isLoading = isLoading
        },
    },
    actions: {
    }
}