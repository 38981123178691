// src/store/index.ts
import { createStore } from 'vuex'
import chat from './chat'  // 导入 chat 模块
import user from './user'  // 导入 user 模块
import frist from './frist' // 导入 frist 模块 （用户第一次登录）
import brain from './brain' // 导入 brain 模块

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    chat,  // 添加 chat 模块
    user,  // 添加 user 模块
    frist,  // 添加 frist 模块
    brain  // 添加 brain 模块
  }
})
