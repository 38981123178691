<template>
    <div class="box-b">
        <iframe src="https://v1.shuzinao.com/instruction.php" frameborder="0" height="100%" width="100%"></iframe>
    </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.box-b{
    width: 100%;
}
</style>