// src/store/chat.ts
const HELLO_WORLD: string = process.env.VUE_APP_HELLO_WORLD;

interface ChatLog {
  sender: string; // 辨别身份
  message: string; // 消息
  timestamp: Date; // 时间戳
}

interface ChatState {
  chatLogs: ChatLog[];
}

let chatLogs: ChatLog[] = [
  {
    sender: 'bot',
    message: HELLO_WORLD,
    timestamp: new Date(),
  },
];
const chatItem = localStorage.getItem('chatLogs');
if (chatItem) {
  chatLogs = JSON.parse(chatItem);
}

export default {
  namespaced: true,
  state: {
    chatLogs: chatLogs,
  } as ChatState, // 这里为 state 提供了类型
  getters: {
    chatLogs: (state: ChatState) => state.chatLogs, // 这里为 state 参数提供了类型
  },
  mutations: {
    setChatLog(state: ChatState, log: ChatLog[]){
      state.chatLogs = log
    },
    addChatLog(state: ChatState, log: ChatLog) {
      // 这里为 state 和 log 参数提供了类型
      state.chatLogs.push(log);
      localStorage.setItem('chatLogs', JSON.stringify(state.chatLogs));
    },
    clearChatLog(state: ChatState){
      localStorage.removeItem('chatLogs');
      state.chatLogs = [
        chatLogs[0]
      ]
    }
  },
  actions: {},
};
