import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useStore } from 'vuex';
import HomeView from '../views/HomeView.vue'
// admin
import NewChatView from '../views/admin/ChatView.vue'
import KnBaseView from '../views/admin/KnBaseView.vue'
import ShuZiNaoView from '../views/admin/ShuZiNaoView.vue'
import UserView from '../views/admin/UserView.vue'
import NoteBookView from '../views/admin/NoteBook.vue'
// frist
import SetNameView from '../views/frist/SetNameView.vue';
import SetLogoView from '../views/frist/SetLogoView.vue';
import SetKnowledgebaseView from '../views/frist/SetKnowledgebaseView.vue';
import SetInforView from '../views/frist/SetInforVIew.vue'
import FristEndView from '../views/frist/FristEndView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/next',
    name: 'next',
    component: () => import(/* webpackChunkName: "about" */ '../views/NextView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/RegisterView.vue')
  },
  {
    path: '/frist',
    name: 'frist',
    children: [
      { path: '', component: SetNameView, name: 'frist-name' },
      { path: 'logo', component: SetLogoView, name: 'frist-logo' },
      { path: 'knowledgebase', component: SetKnowledgebaseView, name: 'frist-knowledgebase' },
      { path: 'infor', component: SetInforView, name: 'frist-infor' },
      { path: 'end', component: FristEndView, name: 'frist-end' }
    ],
    component: () => import('../views/frist/FristIndexView.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    children: [
      { path: '', component: NewChatView, name: 'admin-default' },
      { path: 'shuzinao', component: ShuZiNaoView, name: 'admin-shuzinao' },
      { path: 'knbase', component: KnBaseView, name: 'admin-knbase' },
      { path: 'user', component: UserView, name: 'admin-user' },
      { path: 'notebook', component: NoteBookView, name: 'admin-notebook' }
    ],
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/IndexView.vue')
    
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// 添加登录检测
router.beforeEach(async(to, from, next)=>{
  const store = useStore();
  const isLoggedIn = store.getters['user/user']; // 使用新的 isLoggedIn getter
  
  if(!isLoggedIn && to.path !== '/register' && to.path !== '/login' && to.path !== '/next' && to.path !== '/'){
    next('/login')
  } else {
    next()
  }
})
export default router
