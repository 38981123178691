// src/store/frist.ts


interface BrainState {
    name: string,
    logo: string,
    knowledgebase: string,
    infor:string,
}

export default {
    namespaced: true,
    state: {
        name: '',
        logo: '',
        knowledgebase: '',
        infor: '',
    } as BrainState,
    getters: {
        name: (state: BrainState) => state.name,
        logo: (state: BrainState) => state.logo,
        knowledgebase: (state: BrainState) => state.knowledgebase,
        infor: (state: BrainState) => state.infor
    },
    mutations: {
        setName: (state: BrainState, name: string) => {
            state.name = name
        },
        setLogo: (state: BrainState, logo:  string) => {
            state.logo = logo
        },
        setKnowledgebase: (state: BrainState, knowledgebase: string) => {
            state.knowledgebase = knowledgebase
        },
        setInfor: (state: BrainState, infor: string) => {
            state.infor = infor
        }
    },
    actions: {
    },
}