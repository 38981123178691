
import { Options, Vue } from 'vue-class-component';
import { CaretLeft } from '@element-plus/icons-vue'

@Options({
    props: {
        avatar: {
            type: String,
            default: '/logo/favicon.ico.png'
        },
        msg: {
            type: String,
            default: '暂无消息'
        },
        role: {
            type: String,
            default: 'bot'
        }
    },
    components: {
        CaretLeft
    }
})
export default class ChatItem extends Vue{
    avatar!: string
    msg!: string
    role!: string
}
