
import { Options, Vue } from 'vue-class-component';
import FristSetName from '@/components/frist/SetName.vue'; // @ is an alias to /src

@Options({
  components: {
      FristSetName,
  },
})
export default class SetNameView extends Vue {}
