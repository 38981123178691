import fetchApi from "@/api";
import { util } from '@/util'
import { UserLogin, UserLoginOut, UserLoginData as User } from '@/util/apiType'
// src/store/user.ts

  let loginUser = null, rememberMe = false
  const userItem = localStorage.getItem('user');
  if (userItem) {
    loginUser = JSON.parse(userItem);
    rememberMe = true
  }
  
  interface UserState {
    user: User | null;
    rememberMe: boolean;
    error: string | null;
  }
  
  interface LoginPayload {
    username: string;
    password: string;
    rememberMe: boolean;
  }

  interface ActionContext {
    commit: (mutation: string, payload?: any) => void;
    state: UserState;
  }
  
  export default {
    namespaced: true,
    state: {
      user: loginUser,
      rememberMe: rememberMe,
      error: null,
    } as UserState,
    getters: {
      user: (state: UserState) => state.user,
      rememberMe: (state: UserState) => state.rememberMe,
      error: (state: UserState) => state.error,
    },
    mutations: {
      setUser(state: UserState, user: User) {
        state.user = user;
      },
      setRememberMe(state: UserState, rememberMe: boolean) {
        state.rememberMe = rememberMe;
      },
      setError(state: UserState, error: string) {
        state.error = error;
      },
    },
    actions: {  
        async login({ commit, state }: ActionContext, { username, password, rememberMe }: LoginPayload) {
            commit('setError', null);  // Clear error
            commit('setRememberMe', rememberMe);  // Set "remember me"
            try {
                // Call login API, this is just an example, actual usage may vary
                const response = await fetchApi<UserLogin>('/user/login', {
                    method: 'POST',
                    // headers: {
                    //     'Content-Type': 'application/json',
                    // },
                    body: util.jsonToUrlEncoded({ username, password }),
                });
                const data = await response.data;
                if (response.code === 200 && data.data) {
                  const d = data.data
                    commit('setUser', { id: d.id, nickname: d.nickname, avatar_url: d.avatar_url });
                    if (state.rememberMe) {
                        // If "remember me" is true, save user info to localStorage
                        localStorage.setItem('user', JSON.stringify(state.user));
                    }
                } else {
                    commit('setError', data.message);
                }
            } catch (error) {
                // Check if error is an instance of Error, otherwise use a default error message
                commit('setError', error instanceof Error ? error.message : 'An error occurred during login.');
            }
        },
        async logout({ commit }: ActionContext) {
            try {
              const response = await fetchApi<UserLoginOut>('/user/logout', {
                method: 'POST'
              });
              const data = await response.data;
              if(response.code === 200 && data.code === 200){
                commit('setUser', null);  // Clear user info
                commit('setRememberMe', false);  // Clear "remember me"
                localStorage.removeItem('user');  // Remove user info from localStorage
              }
            } catch (error){
              commit('setError', error instanceof Error ? error.message : 'An error occurred during logout.')
            }
        },
        loadUserFromLocalStorage({ commit, state }: ActionContext) {
            const user = localStorage.getItem('user');
            if (user) {
                commit('setUser', JSON.parse(user));
                commit('setRememberMe', true);
            }
        },
    },
  }
  