import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["item", _ctx.role === 'bot'?'left':'right'])
  }, [
    _createVNode(_component_el_avatar, {
      size: 40,
      src: _ctx.avatar
    }, null, 8, ["src"]),
    _createVNode(_component_el_card, { style: {"max-width":"70%"} }, {
      default: _withCtx(() => [
        _createVNode(_component_el_text, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.msg), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 2))
}