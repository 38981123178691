import fetchApi from "..";
import { UserBrainsCreate, BrainsCreateType, BrainsQuery, BrainsUpdateRequest } from "@/util/apiType";
import { util } from "@/util";

/**
 * 创建数字脑
 */
const create = async ({
    body
}: {
    body: BrainsCreateType
}) => {
    const response =  await fetchApi<UserBrainsCreate>('/brain/createBrain', {
        method: 'POST',
        body: util.jsonToUrlEncoded(body)
    })
    return response
}
/**
 * 删除数字脑
 */
const deleteById = async ({
    id
}:{
    id: string
}) => {
    const response =  await fetchApi<BrainsQuery>('/brain/softDeleteBrainById', {
        method: 'POST',
        body: util.jsonToUrlEncoded({id})
    })
    return response
}
/**
 * 更新数字脑
 */
const update = async ({
    body
}:{
    body: BrainsUpdateRequest
}) => {
    const response =  await fetchApi<BrainsQuery>('/brain/updateBrain', {
        method: 'POST',
        body: util.jsonToUrlEncoded(body)
    })
    return response
}

/**
 * 依据用户id查数字脑
 */
const queryByUserId = async ({
    userId
}: {
    userId: string
}) => {
    const response = await fetchApi<BrainsQuery>(`/brain/getBrainsByUserId?userId=${userId}`, {
        method: 'GET'
    })
    return response
}
/**
 * 根据数字脑名称模糊查询
 */
const queryByName = async ({
    userId,
    name
}: {
    userId: string,
    name: string
}) => {
    const response = await fetchApi<BrainsQuery>(`/brain/getBrainsByName?name=${name}&userId=${userId}`, {
        method: 'GET'
    })
    return response
}
/**
 * 根据数字脑id获取数字脑信息
 */
const queryByID = async ({
    id
}: {
    id: string
}) => {
    const response = await fetchApi<BrainsQuery>(`/brain/getBrainById?id=${id}`, {
        method: 'GET'
    })
    return response
}

/**
 * 统一接口调用形式: brainFun.create()
 */
export const brainFun = {
    create,
    deleteById,
    update,
    queryByID,
    queryByName,
    queryByUserId
}