
  import { Options, Vue } from 'vue-class-component';
  import FristSetInfor from '@/components/frist/SetInfor.vue'; // @ is an alias to /src
  
  @Options({
    components: {
        FristSetInfor,
    },
  })
  export default class SetInforView extends Vue {}
  